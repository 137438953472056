import React from 'react';
import styled from 'styled-components';
import { Box, Card, Image } from 'rebass';
import banner from '../../assets/banner.jpg';

const List = styled.ul`
  list-style-type: none;
  li {
    margin-bottom: 0.2rem;
    span {
      &:first-child {
        display: inline-block;
        width: 30px;
        text-align: center;
      }
    }
  }
`;

function CoursesHeader() {
  return (
    <header>
      {/* <Box
        sx={{
          px: 4,
          py: [5, 5, 4, 4],
          backgroundImage: `url(${banner})`,
          backgroundSize: 'cover',
          borderRadius: 5,
          color: 'white',
          bg: 'gray',
        }}
      >
      </Box> */}
      <Card width={'100%'} pt={3}>
        <Image src={banner} />
      </Card>

      <h3>Napovednik</h3>
      <Box
        sx={{
          width: '100%',
          height: 0,
          paddingBottom: 900 / 16 + '%',
          position: 'relative',
          overflow: 'hidden',
          '& > iframe': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            bottom: 0,
            left: 0,
            border: 0,
          },
        }}
      >
        <iframe
          title="God z nami - Desi Maxwell"
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/sFXQi3raWAQ"
          frameBorder="0"
          allowFullScreen
        />
      </Box>

      <h3>Opis videoserije</h3>
      <p>
        Med platnicama knjige, imenovane Sveto pismo, je bogastvo osupljivih
        pripovedi kot na primer pripoved o Noetovi ladji, Davidovi zmagi nad
        Goljatom, Jezusovem rojstvu in vstajenju. A vse te zgodbe so pravzaprav
        del mnogo večje zgodbe – zgodbe, ki sega od stvarjenja našega kozmosa do
        vélikega vrhunca, poustvaritve kozmosa. {' '}
      </p>

      <p>
        Osrednjo vlogo v tej zgodbi ima vsemogočni Bog, ki vedno znova prihaja
        dol, da bi bil s svojim ljudstvom. Pride v vrt, v puščavo, v tempelj;
        pride v mesu, v Svetem Duhu – in nazadnje bo prebival med svojim
        ljudstvom v novem stvarstvu, katerega središče bo on.{' '}
      </p>
    </header>
  );
}

export default CoursesHeader;

// eslint-disable-next-line no-lone-blocks
{
  /* 
<li>
  <span role="img" aria-label="memo">
    📝
  </span>{' '}
  Use .mdx file to save course & lesson information and notes. This is a
  test
</li>
<li>
  <span role="img" aria-label="video Camera">
    📹
  </span>{' '}
  Youtube as video host
</li>
<li>
  <span role="img" aria-label="motorcycle">
    🏍️
  </span>{' '}
  Support autoplay
</li>
<li>
  <span role="img" aria-label="chart increasing">
    💹
  </span>{' '}
  Save learning progress
</li>
<li>
  <span role="img" aria-label="heart">
    💖
  </span>{' '}
  Bookmark courses
</li> */
}
